<template>
  <div id="app">
    <b-modal id="call-modal" centered hide-footer size="md">
      <template #modal-header>
        <h3 class="my-auto ">
          Заказать <span class="blue-text">звонок</span>
        </h3>
        <div class="close my-auto p-1" @click="$bvModal.hide('call-modal')">
          <span class="material-icons-round mt-auto mb-auto mr-2">close</span>
        </div>
      </template>
      <b-form>
        <b-form-group
            class="mb-3"
            id="fieldset-1"
            label="Как вас зовут?"
            label-for="input-name"
        >
          <input required placeholder="Иван Иванович" id="input-name" class="form-control" v-model="modalData.name" trim/>
        </b-form-group>
        <b-form-group
            class="mb-3"
            id="fieldset-1"
            label="Номер телефона"
            label-for="input-phone"
        >
          <input required placeholder="8-912-345-67-89" type="tel" class="form-control" v-model="modalData.phone" id="input-phone" trim/>
        </b-form-group>
        <b-form-group
            class="mb-3"
            id="fieldset-1"
            label="Когда Вам позвонить?"
            label-for="input-time"
        >
          <input placeholder="Как можно скорее" class="form-control" v-model="modalData.time" id="input-time" trim/>
        </b-form-group>
        <div class="d-flex">
          <button class="submit-request-btn m-auto w-100" type="submit" @click="sendForm">Отправить!</button>
        </div>
      </b-form>
    </b-modal>
    <b-modal id="success-request" content-class="text-center" title="Заявка отправлена!" centered hide-footer>
      <h2 class="blue-text font-weight-normal mb-3">Спасибо!</h2>
      <div>Ваша заявка отправлена! <br> В скором времени мы свяжемся с Вами для обсуждения подробностей!</div>
    </b-modal>
    <b-sidebar id="sidebar-1" title="Навигация" shadow right backdrop backdrop-variant="main-blue" bg-variant="side-menu" width="220px">
      <template v-slot:footer>
        Позвоните нам!<br>
        <span>
            <a href="tel:89123456789" class="text-white">
                8-912-345-67-89
            </a>
        </span>
      </template>
      <div class="side-links">
        <router-link exact class="side-menu-link" tag="div" to="/">О нас</router-link>
        <router-link exact class="side-menu-link" tag="div" to="/calculator">Калькулятор</router-link>
        <router-link exact class="side-menu-link" tag="div" to="/services">Услуги</router-link>
        <router-link exact class="side-menu-link" tag="div" to="/works">Работы</router-link>
        <router-link exact class="side-menu-link" tag="div" to="/contacts">Контакты</router-link>
      </div>
    </b-sidebar>
    <div id="nav" class="navigation" :class="navBack ? 'nav-background' : ''">
      <b-container fluid class="d-flex justify-content-center">
        <div class="links d-flex">
          <router-link exact class="navigation-link mt-auto mb-auto" to="/" active-class="current-link">О нас</router-link>
          <router-link exact class="navigation-link mt-auto mb-auto" to="/calculator" active-class="current-link">Калькулятор</router-link>
          <router-link exact class="navigation-link mt-auto mb-auto" to="/services" active-class="current-link">Услуги</router-link>
          <router-link exact class="navigation-link mt-auto mb-auto" to="/works" active-class="current-link">Работы</router-link>
          <router-link exact class="navigation-link mt-auto mb-auto mr-0" to="/contacts" active-class="current-link">Контакты</router-link>
        </div>
        <a href="tel:89535823354">
        <button class="phone-btn d-none d-md-flex ml-2">
          <i class="material-icons-round gradient-text mr-1">phone</i>
          <span class="d-none d-xl-block">
              8-953-582-33-54
          </span>
        </button>
        </a>
        <button v-b-toggle.sidebar-1 class="mobile-sidebar-open d-block d-sm-none"><span class="material-icons-round">menu</span></button>
      </b-container>
    </div>
    <div class="view">
<!--      <transition>-->
        <router-view class="child-view" />
<!--      </transition>-->
    </div>
    <footer>
      <b-container>
        <b-row>
          <div class="col-12 col-md-5 mb-3 mb-md-0">
            <a href="/"><img class="footer-logo" src="@/assets/logo.webp" alt="logo"></a>
            <h1 class="mt-2 text-white font-weight-light footer-desc">Электромонтажные работы<br>в Красноярске</h1>
          </div>
          <div class="col-12 col-md-3 mb-3 mb-md-0 footer-nav text-left">
            <router-link tag="p" class="footer-link" to="/" active-class="current-link">О нас</router-link>
            <router-link tag="p" class="footer-link" to="/calculator" active-class="current-link">Калькулятор</router-link>
            <router-link tag="p" class="footer-link" to="/services" active-class="current-link">Услуги</router-link>
            <router-link tag="p" class="footer-link" to="/works" active-class="current-link">Наши работы</router-link>
            <router-link tag="p" class="footer-link" to="/contacts" active-class="current-link">Контакты</router-link>
          </div>
          <div class="col-12 col-md-4 text-right ml-auto">
              <div class="phone">
                  <span class="material-icons-round mt-auto mb-auto mr-2">phone</span>
                  <a href="tel:89535823354" class="Blondie text-white">
                      8-953-582-33-54
                  </a>
              </div>
            <div class="time"><span class="material-icons-round mt-auto mb-auto mr-2">schedule</span> Каждый день<br>С 10:00 до 21:00</div>
          </div>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import {BACKEND} from "../backend.config";

export default {
  data() {
    return {
      modalData: {
        name: "",
        phone: "",
        time: ""
      },
      scrollTop: window.pageYOffset || document.documentElement.scrollTop
    }
  },
  computed: {
    navBack() {
      return this.$route.path !== "/" || this.scrollTop > 0
    }
  },
  methods: {
    scroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop
    },
    sendForm(e) {
      e.preventDefault()

      axios.post(BACKEND + "/sendform", this.modalData).then(() => {
        console.log("Форма отправлена!")
        this.$bvModal.show("success-request")
      }).catch(() => {
        console.log("Ошибка отправки!")
      })
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scroll)
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "style";

.fade-enter-active, .fade-leave-active {
  transition: opacity .75s ease;
  position: absolute;
  width: 100%;
  height: 200vh;
}
.fade-enter, .fade-leave-active {
  opacity: 0;
  position: absolute;
  height: 200vh;
  width: 100%;
}
.child-view {
  transition: all .75s cubic-bezier(.55,0,.1,1);
}

html, body{
  font-family: 'Montserrat', sans-serif;
  background: #F3F3F3;
}

body {
  margin: 0;
}

.view{
  min-height: 76vh;
}

.navigation{
  position: fixed;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 5;
  transition: all .3s ease-in-out;
  div{
    overflow-y: hidden;
  }
}

.navigation-link{
  margin-right: 16px;
  color: white;
  font-size: 24px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  transition: all .3s ease-in-out;
}

.navigation-link:hover{
  text-decoration: none;
  transform: translateY(5px);
  color: var(--blue-secondary);
}

@media screen and (max-width: 1000px){
  .navigation-link{
    font-size: 20px;
    white-space: nowrap;
  }
}

@media screen and (max-width: 635px) {
  .links{
    overflow-x: auto;
  }

  .navigation-link{
    font-size: 16px;
    white-space: nowrap;
  }
}

.current-link{
  color: var(--blue);
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.mobile-sidebar-open{
  background: none;
  border: none;
  margin-top: 8px;
  padding: 0 0 0 10px;

  span{
    font-size: 32px;
    color: white;
  }
}

.nav-background{
  border-radius: 0 0 15px 15px;
  background: rgba(152, 202, 255, 0.9);
  box-shadow: 0 8px 32px 0 rgba( 31, 55, 160, 0.25 );
  //backdrop-filter: blur( 5px );
  //-webkit-backdrop-filter: blur( 5px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  border-top: none;
}

.phone-btn{
  border-radius: 50px;
  padding: 10px 20px;
  background: white;
  color: var(--blue-main);
  border: none;
  box-shadow: 0 0px 12px rgba(255, 255, 255, 0.47);
}

.burger-menu{
  i{
    font-size: 36px;
  }
}

footer{
  background: var(--blue-main);
  padding: 20px;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -8px 15px rgba(0, 0, 0, 0.25);
  margin-top: 50px;
  //flex-shrink: 0;
  //position: absolute;
  //bottom: 0;
  //width: 100%;
}

.footer-logo{
  width: 75%;
}

.footer-desc{
  font-size: 16px;
}

.footer-link{
  font-size: 18px;
  color: white;
  margin-bottom: 0;
  cursor: pointer;
  transition: all .3s;
}

.footer-link:hover{
  text-decoration: underline;
}

@media screen and (max-width: $md){
  footer{
    text-align: center;
  }
}

.b-sidebar{
  border-radius: 15px 0 0 15px;
  overflow: hidden;
}

.b-sidebar-footer{
  border-radius: 15px 0 0 0;
  box-shadow: 0 -6px 10px rgb(0 0 0 / 10%);
  color: white;
  span{
    text-decoration: underline;
    font-size: 20px;
  }
}

.bg-main-blue{
  background: rgba(86, 136, 221, 0.55);
}

.bg-side-menu{
  background: #F3F3F3;
}

.side-links{
  margin-top: 20px;
  padding: 20px;
}

.side-menu-link{
  font-size: 24px;
  text-align: right;
}

.submit-request-btn{
  border-radius: 50px;
  padding: 15px 30px;
  line-height: 1;
  background: linear-gradient(93.86deg, #3CB3FF -1.19%, #49B8FF -1.18%, #98D7FF 133.59%);
  border: none;
  color: white;
}
</style>

<style scoped lang="scss">
.phone, .time{
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  //justify-content: flex-end;
  color: white;
  font-size: 21px;
  text-align: left;
  span{
    font-size: 36px;
  }
}

.time{
  font-size: 18px;
  margin-top: 10px;
}
</style>
