<template>
  <div class="feature-tile">
    <div class="content">
      <div class="circle-icon">
        <slot name="icon" class="icon-img"></slot>
      </div>
      <div class="feature-name">
        <slot name="name"></slot>
      </div>
      <div class="feature-description">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureTile",
  props: {
    name: String
  }
}
</script>

<style scoped lang="scss">
.circle-icon{
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  margin-left: auto;
  margin-right: auto;
  //margin-top: -50px;
  position: relative;
  top: -50px;
  background: #F3F3F3;
  display: flex;
  img{
    width: 75%;
    margin: auto;
  }
}

.feature-tile{
  //margin-bottom: 70px;
  margin-top: 80px;
}

.content{
  border-radius: 50px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  padding: 0 20px 35px;
  background: #F3F3F3;
  height: 100%;
  display: flex;
  flex-direction: column;;
}

.feature-name{
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 12px;
  margin-top: -30px;
}

.feature-description{
  margin-top: auto;
  margin-bottom: auto;
}
</style>