<template>
  <div class="home">
    <header class="header">
      <b-container class="text-center">
        <div class="logo">
          <img src="@/assets/logo.webp" alt="">
        </div>
        <div class="slogan text-center">
            <div class="slogan-change">
                <vue-typed-js class="d-block" :strings="slogans" :loop="true" :fadeOut="true" :back-delay="2000" :fadeOutDelay="2000" :cursor-char="'&nbsp;'">
                    <span class="typing"></span>
                </vue-typed-js>
            </div>
            <div class="slogan-professional">Поручите это<br><span class="blue-text professional">профессионалам!</span></div>
            <a href="tel:89535823354" class="Blondie text-white tel-font">
                <br />
                +79535823354
            </a>
        </div>
        <a href="#phone" target="_self" class="request-button anchor">Закажите звонок!</a>
        <div class="or">или</div>
        <router-link class="use-calc d-inline" tag="div" to="/calculator">Воспользуйтесь<br>калькулятором</router-link>
        <div class="scroll-down"><i class="material-icons-round">expand_more</i></div>
      </b-container>
    </header>
    <section class="services">
      <b-container>
        <h1><span class="blue-text">Электрики</span> в Красноярске</h1>
        <h2>Все виды электромонтажных работ под ключ!</h2>
        <b-row class="mb-3">
          <div class="service-tiles d-flex flex-wrap col-lg-6 justify-content-center">
            <service-tile id="tile-1" :img="tile1" :active="activeTile === 1">Монтаж кабеля</service-tile>
            <service-tile id="tile-2" :img="tile2" :active="activeTile === 2">Установка розеток</service-tile>
            <service-tile id="tile-3" :img="tile3" :active="activeTile === 3">Под&shy;ключение счётчиков</service-tile>
            <service-tile id="tile-4" :img="tile4" :active="activeTile === 4">Монтаж све&shy;тильников</service-tile>
            <service-tile id="tile-5" :img="tile5" :active="activeTile === 5">Установка распаячных коробок</service-tile>
            <service-tile id="tile-6" :img="tile1" :active="activeTile === 6">Штробление стен</service-tile>
          </div>
          <div class="description d-lg-flex col-lg-6 flex-column d-none text-left">
            <h3>{{services[activeTile - 1].name}}</h3>
            {{ services[activeTile - 1].text }}
            <button class="request-service-btn mt-auto ml-auto mr-auto mb-2">Заказать {{services[activeTile - 1].button}}</button>
          </div>
        </b-row>
        <router-link to="/services" class="text-center full-price blue-text mt-2">Смотреть полный прайс и цены</router-link>
      </b-container>
    </section>
    <section class="features">
      <b-container>
        <h1><span class="blue-text">Преимущества</span> работы с нами</h1>
        <h2>Быстро приедем к Вам и сделаем все в лучшем виде!</h2>
        <b-row class="feature-tiles d-flex flex-wrap justify-content-center">
          <feature-tile class="col-lg-4 col-sm-6">
            <template v-slot:icon><img src="../assets/money4.svg" alt=""></template>
            <template v-slot:name>
              Прозрачное цено&shy;образование
            </template>
            Мы работаем исключительно по прайсу, который находится в открытом доступе - никаких скрытых наценок и дополнительных платежей!
          </feature-tile>
          <feature-tile class="col-lg-4 col-sm-6">
            <template v-slot:icon><img src="../assets/stars.svg" alt=""></template>
            <template v-slot:name>
              Идеальное качество
            </template>
            Каждый электромонтажник нашей компании имеет многолетний опыт в области электрификации. Мы применяем самые современные методы работы для достижения наилучших результатов в короткие сроки.
          </feature-tile>
          <feature-tile class="col-lg-4 col-sm-6">
            <template v-slot:icon><img src="../assets/bag.svg" alt=""></template>
            <template v-slot:name>У нас работают профес&shy;сионалы</template>
            Каждый сотрудник нашей компании является профессионалом в своей области, что позволяет нам предоставлять клиентам полный комплекс услуг электромонтажных работ!
          </feature-tile>
          <feature-tile class="col-lg-4 col-sm-6">
            <template v-slot:icon><img src="../assets/drill.svg" alt=""></template>
            <template v-slot:name>Профес&shy;сиональное оборудование</template>
            Наши специалисты используют только профессиональное оборудование для электромонтажных работ.
            С его помощью работа выполняется быстрее, качественнее и удобнее!
          </feature-tile>
          <feature-tile class="col-lg-4 col-sm-6">
            <template v-slot:icon><img src="../assets/gloves.svg" alt=""></template>
            <template v-slot:name>Техника безопасности</template>
            Наши сотрудники выполняют работы, строго следуя технике безопасности. Для производства работ, в соответствии с требованиями, мы используем необходимые средства защиты
          </feature-tile>
          <feature-tile class="feature-request col-lg-4 col-sm-6">
            <template v-slot:icon><img src="../assets/contact-list.svg" alt=""></template>
            <template v-slot:name>Закажите электрика уже сейчас!</template>
            <div>
              Позвоним в удобное время и обсудим тонкости работы!
            </div>
            <button v-b-modal.call-modal class="blue-text feature-request-btn" >Заказать!</button>
          </feature-tile>
        </b-row>
      </b-container>
    </section>
    <section class="call-request">
        <b-container id="phone">
        <h1><span class="blue-text">Электромонтаж</span> в Красноярске</h1>
        <h2>Качественно и недорого!</h2>
        <b-overlay :show="reqSending" rounded="md">
          <div class="call-form col-12 col-xl-10 offset-xl-1">
            <h3>Нужен хороший мастер?<br><span class="blue-text">Вы по адресу!</span></h3>
            <b-form class="d-flex w-75 flex-wrap ml-md-auto mr-md-auto ml-lg-0 " @submit="sendForm">
              <b-form-group
                  class="col-md-6 col-12"
                  id="fieldset-1"
                  label="Как вас зовут?"
                  label-for="input-name"
                  :state="request.name !== ''"
              >
                <input required class="form-control" id="input-name" name="name" v-model="request.name" trim/>
              </b-form-group>
              <b-form-group
                  class="col-md-6 col-12"
                  id="fieldset-1"
                  label="Номер телефона"
                  label-for="input-phone"
                  :state="request.phone !== ''"
              >
                <input required type="tel" class="form-control" id="input-phone" name="tel" v-model="request.phone" trim/>
              </b-form-group>
              <b-form-group
                  class="col-md-6 col-12"
                  id="fieldset-1"
                  label="Когда Вам позвонить?"
                  label-for="input-time"
              >
                <input placeholder="Как можно скорее" class="form-control" id="input-time" name="time" v-model="request.time" trim/>
              </b-form-group>
              <div class="col-md-6 col-12 d-flex">
                <button class="submit-request-btn m-auto w-100" type="submit">Отправить!</button>
              </div>
              <img src="../assets/electrician.webp" class="electrician d-none d-md-block" alt="">
            </b-form>
          </div>
        </b-overlay>
      </b-container>
    </section>
  </div>
</template>

<script>
import tile1 from "@/assets/tile1.webp"
import tile2 from "@/assets/tile2.webp"
import tile3 from "@/assets/tile3.webp"
import tile4 from "@/assets/tile4.webp"
import tile5 from "@/assets/tile5.webp"

import ServiceTile from "@/components/ServiceTile";
import FeatureTile from "@/components/FeatureTile";
import axios from "axios";
import {BACKEND} from "../../backend.config";

export default {
  name: 'Home',
  data() {
    return {
      tile1,
      tile2,
      tile3,
      tile4,
      tile5,
      activeTile: 1,
      slogans: ["Проложить проводку?", "Сделать свет?", "Подключить розетки?", "Подключить счётчик?", "Штробление стены?", "Собрать автоматы?"],
      services: [
        {
          name: "Монтаж кабеля",
          text: "Специалисты Электрик24 имеют большой опыт в прокладывании кабеля в стенах и потолках.\n" +
              "Мы знаем все нюансы данной работы, поэтому выполняют ее профессионально и качественно.\n" +
              "Стоимость работ электриков в Красноярске рассчитывается, как правило, после того, как замерщик приедет на объект и сделает необходимые замеры.\n" +
              "В прайс-листе указана средняя стоимость услуг наших специалистов, которая при желании может быть скорректирована в сторону увеличения/уменьшения в зависимости от объема работ и сложности их выполнения.",
          button: "монтаж кабеля"
        },
        {
          name: "Установка розеток",
          text: "Наши специалисты устанавливают любые розетки на любых объектах.\n" +
              "Мы готовы выехать на замеры для любого района Красноярска.\n" +
              "Все работы мы проводим в строгом соответствии с установленными стандартами, в короткие сроки и за разумную цену.\n" +
              "Наши клиенты получают квалифицированную помощь и качественные услуги от профессионалов с богатым опытом.\n" +
              "У нас вы можете заказать весь комплекс услуг – от монтажа розеток до прокладки слаботочных сетей.",
          button: "установку розеток"
        },
        {
          name: "Подключение счётчиков",
          text: "Наши специалисты имеют огромный опыт в установке и замене счётчиков электроэнергии. При замене счетчиков Вы можете быть полностью уверены в качестве произведенной работы и ее безопасности для Вас. Мы производим замену счетчика на новый без вскрытия счетчика. Перед заменой счетчика производится проверка напряжения тока и сопротивления изоляции, так же проверяется исправность самого прибора. Все счетчики проходят поверку, а перед выдачей клиенту мы обязательно проверяем работоспособность счетчиков с помощью специальных измерительных установок.",
          button: "подключение счётчика"
        },
        {
          name: "Монтаж светильников",
          text: "Квалифицированные специалисты Электрик24 имеют большой опыт в установке светильников, которые помогают сделать помещение красивым и комфортным для проживания.\n" +
              "Светильники могут быть любого типа - от светодиодных лент до дизайнерских решений.\n" +
              "Установка светильников в Красноярске осуществляется с помощью специализированных инструментов и оборудования.",
          button: "монтаж светильников"
        },
        {
          name: "Установка распаечных коробок",
          text: "Установка распаечных коробок - одно из основных направлений работ мастера электрика.\n" +
              "Работы, выполняемые по установке распаечных коробок, в первую очередь, это ремонт и замена старой распайки на новую.\n" +
              "Во время ремонта, когда не хватает места для монтажа новых розеток, выключателей, светильников, в старых бетонных стенах очень часто приходится делать отверстия или штробы в стенах для распаячных коробок.\n" +
              "В этом случае, установка распаечной коробки необходима.",
          button: "установку коробок"
        },
        {
          name: "Штробление стен",
          text: "При штроблении стен специалисты Электрик24 используют специальную технику.\n" +
              "Штробление проводится на стенах из кирпича или камня.\n" +
              "Также с помощью штробления можно прокладывать кабель под штукатурку.\n" +
              "Если работа проводится в бетонной стене, то в нее вбивается дюбель и забивается перфоратор, после чего в стенку вставляется штроборез и начинает работать.\n" +
              "Этот способ более безопасный но требует наличия профессиональных навыков.\n" +
              "В большинстве случаев, подключение к электрической сети проводится скрыто, в самом основании стены.",
          button: "штробление стен"
        },
      ],
      request: {
        name: "",
        phone: "",
        time: ""
      },
      reqSending: false
    }
  },
  methods: {
    logger(event) {
      console.log(event)
      },
    sendForm(e) {
      e.preventDefault()

      this.reqSending = true
      axios.post(BACKEND + "/sendform", this.request).then(() => {
        console.log("Форма отправлена!")
        this.reqSending = false
        this.$bvModal.show("success-request")
      }).catch(() => {
        this.reqSending = false
        console.log("Ошибка отправки!")
      })
    }
  },
  mounted() {
    for (let i = 1; i <= 6; i++) {
      document.getElementById("tile-" + i).addEventListener("mouseenter", (e) => {
        let currentId = e.currentTarget.id
        this.activeTile = parseInt(currentId.replace("tile-", ""))
      })
    }
  },
  components: {
    FeatureTile,
    ServiceTile
  }
}
</script>

<style scoped lang="scss">
@import "../style";
header{
  background: url("../assets/back.webp") no-repeat;
  background-size: cover;
  min-height: 100vh;
  border-radius: 0 0 40px 40px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.25);
}

.logo{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  img{
    margin-top: 15vh;
    width: 100%;
  }
}

.slogan{
  margin-top: 10vh;
  margin-bottom: 10vh;
  font-size: 48px;
  font-weight: 400;
  color: white;
  line-height: 1.2;
  .slogan-change{
    margin-bottom: 2vh;
  }
}

.professional{
  font-weight: 500;
}

.tel-font{
    font-size: 36px;
}

@media screen and (max-width: 992px){
  .tel-font{
    font-size: 28px;
  }
}

.request-button{
  border-radius: 50px;
  padding: 20px 30px;
  font-size: 32px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  background: linear-gradient(93.86deg, #3CB3FF -1.19%, #49B8FF -1.18%, #98D7FF 133.59%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.57);
  color: white;
  animation: glow infinite ease-in-out 2s;
  animation-delay: 1s;
}

.or{
  margin-bottom: 2vh;
  margin-top: 2vh;
}

.or, .use-calc{
  color: white;
  font-size: 24px;
  line-height: 1;
  cursor: pointer;
}

.use-calc{
  text-decoration: underline;
  color: white;
}

.scroll-down{
  margin-top: 3vh;
  i{
    color: white;
    font-size: 48px;
  }
  animation: up-down 1.5s ease-in-out infinite;
}

@keyframes up-down {
  0%, 100% {
  transform: none;
  }

  50% {
    transform: translateY(10px);
  }
}

@keyframes glow {
  0%, 100% {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.57);
  }

  50%{
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.9);
  }
}

@media screen and (max-width: $lg){
  .slogan{
    font-size: 32px;
  }
}

@media screen and (max-width: $sm){
  header{
    border-radius: 0 0 20px 20px;
  }

  .slogan{
    font-size: 24px;
  }

  .request-button{
    font-size: 24px;
    padding: 15px 20px;
  }

  .or, .use-calc{
    font-size: 18px;
  }
}

@media screen and (max-width: $lg){
  .logo{
    width: 75%;
  }
}

@media screen and (max-width: $md){
  .logo{
    width: 100%;
  }
}

section{
  margin-top: 60px;
  text-align: center;
  h2{
    margin-bottom: 5vh;
  }
}

.request-service-btn{
  background: linear-gradient(93.86deg, #3CB3FF -1.19%, #49B8FF -1.18%, #98D7FF 133.59%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.57);
  color: white;
  border-radius: 50px;
  padding: 15px 20px;
  border: none;
}

.full-price{
  text-decoration: underline;
}

.anchor {
  text-decoration: none;
}

.call-form{
  h3{
    text-align: left;
    margin-bottom: 2vh;
  }
}

@media screen and (max-width: $md){
  .call-form{
    h3{
      font-size: 20px;
      text-align: center;
    }
  }
}

@media screen and (min-width: $xl){
  .feature-tiles{
    padding: 0 5%;
  }
}

.call-form{
  background: #F3F3F3;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  padding: 30px 20px;
  text-align: left;
  border-radius: 20px;
  overflow: hidden;
  *{
    z-index: 1;
  }
}

@media screen and (max-width: $lg) {
  .call-form{
    form{
      width: 80%!important;
    }
  }
}

@media screen and (max-width: $md) {
  .call-form{
    form{
      width: 100%!important;
    }
  }
}

.electrician{
  position: absolute;
  height: 120%;
  top: -10%;
  right: 0;
  z-index: 0;
  opacity: 0.3;
}
</style>

<style lang="scss">
.feature-request{
  color: white;
  .feature-request-btn{
    border-radius: 50px;
    padding: 15px 40px;
    font-size: 24px;
    background: white;
    border: none;
    margin-top: 25px;
  }
  .feature-description{
    margin-bottom: 0;
  }
  .circle-icon, .content{
    background: linear-gradient(93.86deg, #3CB3FF -1.19%, #49B8FF -1.18%, #98D7FF 133.59%);
  }
}

@media (min-width: 1200px){
  #phone{
    .b-overlay{
      margin: 0 8.333333%;
      div{
        border-radius: 20px!important;
      }
    }
 }
}
</style>