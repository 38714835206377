import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Calculator from '../views/Calculator.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "Электрик24 - электромонтажные работы в Красноярске недорого!",
      description: "Электрик24 - любые электромонтажные работы в Красноярске недорого! Нужно поменять розетку или проложить кабель в стене? Обратитесь к профессионалам! Специалисты Электрик24 имеют огромный опыт во всех электромонтажных работах!"
    }
  },
  {
    path: '/calculator',
    name: 'Calculator',
    component: Calculator,
    meta: {
      title: "Калькулятор стоимости работ - Электрик24",
      description: "Рассчитайте стоимость электромонтажных работ прямо сейчас! Специалисты Электрик24 приедут в любую точку Красноярска и выполнят всю работу в лучшем виде!"
    }
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("../views/Contacts"),
    meta: {
      title: "Контакты - Электрик24",
      description: ""
    }
  },
  {
    path: "/works",
    name: "Works",
    component: () => import("../views/Works"),
    meta: {
      title: "Наши работы - Электрик24",
      description: ""
    }
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../views/Services"),
    meta: {
      title: "Наши услуги - Электрик24",
      description: ""
    }
  },
  {
    path: "/service/installation",
    component: () => import("../views/ServiceLayout"),
    meta: {
      title: "Установка электрооборудования в Красноярске - Электрик 24",
      description: "Установка электрооборудования - розеток, выключателей, счетчиков в Красноярске. Сотрудники Электрик24 выполнят все виды работ по ремонту и замене электрики в квартирах и офисах.",
      name: "Установка оборудования",
      text: "Установка электрооборудования - розеток, выключателей, счетчиков в Красноярске. Сотрудники Электрик24 выполнят все виды работ по ремонту и замене электрики в квартирах и офисах. В офисе или квартире часто меняют устаревшую электропроводку, устанавливают новые розетки, выключатели, светильники. Электротехнические работы требуют квалифицированных инженеров, а также профессиональных инструментов и оборудования. Электрик24 - услуги электрика в Красноярске по доступной стоимости. ",
      pic: "",
      table: [
                  {
          cost: 150,
          title: 'Демонтаж точки',
          count: 1
        },
        {
          cost: 150,
          title: 'Монтажы кабеля (закрытый) (м/п)',
        },
        {
          cost: 100,
          title: 'Монтаж кабеля (открытый) (м/п)',
        },
        {
          cost: 300,
          title: 'Расключение распаечной коробки',
          count: 1
        },
        {
          cost: 3000,
          title: 'Сверление отверстий (м/п)',
          count: 1
        },
      ]
    }
  },
  {
    path: "/service/mount",
    component: () => import("../views/ServiceLayout"),
    meta: {
      title: "Электромонтаж в Красноярске - Электрик24",
      description: "Электромонтажные работы в Красноярске. Наши специалисты из Электрик 24 проведут работы по ремонту и замене электрики в квартире, частном доме, офисе. Мы бесплатно проконсультируем вас по всем вопросам и поможем определиться с выбором материалов и оборудования!",
      name: "Электромонтаж",
      text: "Электромонтажные работы в Красноярске. Наши специалисты из Электрик 24 проведут работы по ремонту и замене электрики в квартире, частном доме, офисе. Мы бесплатно проконсультируем вас по всем вопросам и поможем определиться с выбором материалов и оборудования. Электромонтажные работы проводятся, как в новостройках, так и в старых зданиях. При замене розеток и выключателей, мы используем исключительно электроустановочное оборудование проверенных производителей. В целях вашей безопасности, все провода и кабели проходят через электротехнический короб.",
      pic: "https://nadomkrat.ru/wp-content/uploads/2021/03/tangani-masalah-kelistrikan-dengan-jasa-ahli-kelistrikan-terpercaya-beres.id-.png",
      table: [
                  {
          cost: 300,
          title: 'Установка автомата защиты',
        },
        {
          cost: 500,
          title: 'Установка вентилятора канальтоного',
          count: 1
        },
        {
          cost: 300,
          title: 'Установка выключателя',
        },
        {
          cost: 200,
          title: 'Установка подрозетника',
        },
        {
          cost: 200,
          title: 'Установка распаечной коробки',
        },
        {
          cost: 1000,
          title: 'Установка распределительного щита',
        },
        {
          cost: 300,
          title: 'Установка розетки',
        },
        {
          cost: 500,
          title: 'Установка светильников',
        },
        {
          cost: 1000,
          title: 'Установка счетчика электроэнергии',
        },
        {
          cost: 500,
          title: 'Установка УЗО, дифавтомат',
        },
      ]
    }
  },
  {
    path: "/service/shtroblenie",
    component: () => import("../views/ServiceLayout"),
    meta: {
      title: "Штробление стен недорого - Электрик24",
      description: "Закажите штробление стен в Красноярске недорого! Специалисты Электрик24 выполнят данную работу максимально быстро и качественно.",
      name: "Штробление стен",
      text: "Закажите штробление стен в Красноярске! Специалисты Электрик24 выполнят данную работу максимально быстро и качественно. Вы можете быть уверены, что ваши стены не повреждены, штроба не зарастет пылью или не будет повреждена отделка. Штробление стен под провода необходимо проводить в обязательном порядке, если вы планируете прокладку кабеля в штробах. Это позволит избежать возможных неприятностей. Монтаж проводов в штробе проводится в следующем порядке: штробу очищают от штукатурки до базового основания. Выполняют разметку и выбирают штробу.",
      pic: "https://vld.intt.ru/misc/store/thumbs/e/e4/e40/800x600/e4051f646c92ffa4374f84f8c1de0b0b.jpeg",
      table: [
                  {
          cost: 500,
          title: 'Штробление под кабель в потолке (м/п)',
        },
        {
          cost: 200,
          title: 'Штробление под кабель в стене (м/п)',
        },
        {
          cost: 800,
          title: 'Штробление под канал в потолке',
        },
        {
          cost: 400,
          title: 'Штробление под канал в стене',
        },
        {
          cost: 300,
          title: 'Штробление под подрозетник',
        },
        {
          cost: 500,
          title: 'Штробление под распаячную коробку',
        },
        {
          cost: 2,
          title: 'Штробление под щит (за см²)',
        },
      ]
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  const DEFAULT_TITLE = "Электрик24 - электромонтажные работы в Красноярске недорого!"
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router
