<template>
    <div class="calculator">
        <b-container class="text-center">
            <h1 class="text-center mb-4"><span class="blue-text">Калькулятор</span> услуг</h1>
            <article class="about-calc text-center mb-4">Рассчитайте стоимость услуг самостоятельно и оставьте заявку, а менеджеры Электрик24 свяжутся с Вами для обсуждения дальнейших действий! Работаем по всему Красноярску и области, выполняем полный спектр электромонтажных работ!</article>
            
<!--            <b-row v-for="(r, index) in request" :key="index" class="d-lg-none w-100 mx-auto calculator-service-card">-->
<!--                <h6>Тип: Установка распаечной коробки</h6>-->
<!--                <b-col align-self="stretch">-->
<!--                        <h6>-->
<!--                            <span class="w-25 m-auto">Количество:</span>-->
<!--                            <b-input type="number" :min="0" :max="999" class="service-count-input w-25 m-auto" @focus="" v-model="r.count" />-->
<!--                        </h6>-->
<!--                </b-col>-->
<!--                <b-col align-self="stretch">-->
<!--                    <button @click="removeService(index)" class="w-100 m-auto remove-service d-flex flex-column">-->
<!--                        <i class="material-icons-outlined mt-auto mb-auto mx-auto">delete</i>-->
<!--                        <span class="mx-auto">Удалить</span>-->
<!--                    </button>-->
<!--                </b-col>-->
<!--                <span>Предварительная стоимость:&nbsp;</span>-->
<!--                <span> {{(isNaN(r.count * r.cost)) ? '-' : r.count * r.cost}} ₽</span>-->
<!--            </b-row>-->

            <b-row v-for="(r, index) in request" :key="index" class="w-100 mx-auto calculator-service-card" :class="activity.indexOf(index) !== -1 ? 'active-service' : 'inactive-service'">
                <div class="col-lg-4 col-12 active-view">
                    <h6>Тип</h6>
                    <v-select rounded :clearable="false" placeholder="Выберите услугу" @blur="setInactive(index)" @focus="setActive(index)" v-model="request[index]" class='calculator_first' :options="filtered" label="title" @search="query => search = query" :filterable="false"></v-select>
                </div>
                <div class="col-lg-4 col-12 inactive-view">
                  <h6>Тип: {{ request[index].title }}</h6>
<!--                  <v-select rounded :clearable="false" placeholder="Выберите услугу" @blur="setInactive(index)" v-model="request[index]" class='calculator_first' :options="filtered" label="title" @search="query => search = query" :filterable="false"></v-select>-->
                </div>
                <div class="col-lg-4 col-12 mt-2 mt-lg-0 service-count">
                    <span class="my-auto">Количество: {{ r.count }} шт.</span>
                    <div class="d-flex">
                        <b-input type="range" :max="15" class="w-100 mr-2 my-auto range-slider" v-model="r.count" />
                        <b-input type="number" :min="0" :max="999" @blur="setInactive(index)" @focus="setActive(index)" class="service-count-input w-25 my-auto" v-model="r.count" />
                    </div>
                </div>
                <div class="col-lg-2 col-12 mt-2 mt-lg-0 pred-cost">
                  <div class="d-flex justify-content-center">
                      <h6 class="d-flex flex-column">
                          <span>Предварительная стоимость:</span>
                          <span class="mt-2">{{(isNaN(r.count * r.cost)) ? '-' : r.count * r.cost}} ₽</span>
                      </h6>
                      <button @click="removeService(index)" class="w-75 m-auto remove-service d-flex flex-column">
                        <i class="material-icons-outlined mt-auto mb-auto mx-auto">delete</i>
                        <span class="mx-auto">Удалить</span>
                      </button>
                  </div>
                  <div class="edit-btn text-center">
                    <button @click="setActive(index)">
                      Редактировать
                    </button>
                  </div>
                </div>
                <div class="col-lg-2 col-12 d-flex delete-button">
                    <button @click="removeService(index)" class="w-75 m-auto remove-service d-flex flex-column">
                        <i class="material-icons-outlined mt-auto mb-auto mx-auto">delete</i>
                        <span class="mx-auto">Удалить</span>
                    </button>
                </div>
            </b-row>

            <button @click="addService" class="add-service d-flex mx-auto"><i class="material-icons-outlined mt-auto mb-auto mr-2">add_circle_outline</i> Добавить услугу</button>
            <h5 class="mt-4 summ">Сумма услуг: {{request.map(item => (parseInt(item.count) || 0) * (item.cost || 0)).reduce((accumulator, currentValue) => accumulator + currentValue)}} ₽</h5>
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'Calculator',
        data() {
            return {
                search: '',
                price: [{
                }],
                request: [{
                    title: "Демонтаж точки",
                    count: 1,
                    cost: 150,
                }],
                services: [
                    {
                      cost: 150,
                      title: 'Демонтаж точки',
                      count: 1
                    },
                    {
                      cost: 150,
                      title: 'Монтажы кабеля (закрытый) (м/п)',
                      count: 1
                    },
                    {
                      cost: 100,
                      title: 'Монтаж кабеля (открытый) (м/п)',
                      count: 1
                    },
                    {
                        cost: 300,
                        title: 'Расключение распаечной коробки',
                        count: 1
                    },
                    {
                        cost: 3000,
                        title: 'Сверление отверстий (м/п)',
                        count: 1
                    },
                    {
                        cost: 300,
                        title: 'Установка автомата защиты',
                        count: 1
                    },
                    {
                        cost: 500,
                        title: 'Установка вентилятора канальтоного',
                        count: 1
                    },
                    {
                        cost: 300,
                        title: 'Установка выключателя',
                        count: 1
                    },
                    {
                        cost: 200,
                        title: 'Установка подрозетника',
                        count: 1
                    },
                    {
                        cost: 200,
                        title: 'Установка распаечной коробки',
                        count: 1
                    },
                    {
                        cost: 1000,
                        title: 'Установка распределительного щита',
                        count: 1
                    },
                    {
                        cost: 300,
                        title: 'Установка розетки',
                        count: 1
                    },
                    {
                        cost: 500,
                        title: 'Установка светильников',
                        count: 1
                    },
                    {
                        cost: 1000,
                        title: 'Установка счетчика электроэнергии',
                        count: 1
                    },
                    {
                        cost: 500,
                        title: 'Установка УЗО, дифавтомат',
                        count: 1
                    },
                    {
                        cost: 500,
                        title: 'Штробление под кабель в потолке (м/п)',
                        count: 1
                    },
                    {
                        cost: 200,
                        title: 'Штробление под кабель в стене (м/п)',
                        count: 1
                    },
                    {
                        cost: 800,
                        title: 'Штробление под канал в потолке',
                        count: 1
                    },
                    {
                        cost: 400,
                        title: 'Штробление под канал в стене',
                        count: 1
                    },
                    {
                        cost: 300,
                        title: 'Штробление под подрозетник',
                        count: 1
                    },
                    {
                        cost: 500,
                        title: 'Штробление под распаячную коробку',
                        count: 1
                    },
                    {
                        cost: 2,
                        title: 'Штробление под щит (за см²)',
                        count: 1
                    },
                ],
                items: [{
                    price: 1000,
                }],
                activity: []
            }
        },
  computed: {
    filtered () {
          return this.services.filter(service => service.title.includes(this.search));
      },
    },
  methods: {
    addService: function () {
      this.request.push({
        title: "Выберите услугу...",
      });
      this.activity = [this.request.length - 1]
    },

    removeService: function (index) {
      this.request.splice(index, 1);
    },

    setActive(ind) {
      this.activity = []
      this.activity.push(ind)
      console.log(ind)
    },

    setInactive(ind) {
      this.activity = []
      // this.activity.splice(this.activity.indexOf(ind), 1)
      console.log(ind)
    }

  }
}

</script>

<style scoped lang="scss">
@import "../style";

.active-view {
  display: block;
}
.inactive-view{
  display: none;
}
.range-slider{
  display: block;
}
.pred-cost{
  .remove-service{
    display: none!important;
  }
}
.edit-btn{
  display: none;
}

@media screen and (max-width: 992px) {
  .active-service{
    .active-view {
      display: block;
    }
    .inactive-view{
      display: none;
    }
    .range-slider{
      display: block;
    }
    .pred-cost{
      .remove-service{
        display: none!important;
      }
    }
    .edit-btn{
      display: none;
    }
  }

  .inactive-service{
    .active-view {
      display: none;
    }
    .inactive-view{
      display: block;
    }
    .range-slider{
      display: none;
    }
    .service-count{
      display: flex;
      justify-content: center;
      .service-count-input{
        display: none!important;
      }
    }
    .pred-cost{
      .remove-service{
        display: flex!important;
      }
    }
    .delete-button{
      display: none!important;
    }
    .edit-btn{
      display: block!important;
      button{
        border: var(--blue-main) 2px solid;
        border-radius: 50px;
        padding: 5px 10px;
        background: none;
        color: var(--blue-main);
        font-weight: 500;
      }
    }
  }
}

.calculator{
    padding-top: 110px;
}

.calculator_first{
    width: 100%;
}

.myPlus {
    width: 40px;
    height: 40px;
    background-color: green;
    font-size: 35px;
    border-radius: 20px;
    text-align: center;
    color: white;
    float: right;
    margin-top: 80px;
    margin-right: 80px;
}

.remove-service{
  border-radius: 10px;
  background: rgba(238, 45, 45, 0.8);
  border: none;
  color: white;
  //padding: 5px 10px;
  span{
    font-size: 14px;
  }
}

.calculator-service-card{
  background: #F3F3F3;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}

.about-calc{
  font-size: 20px;
}

.add-service{
  background: var(--blue-main);
  padding: 15px 25px;
  border-radius: 50px;
  border: none;
  color: white;
}

.service-count-input{
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(60,60,60,.26);
  outline: none;
}

.service-count-input:active, .service-count-input:focus{
  outline: none;
  box-shadow: none;
}

@media screen and (max-width: $md) {
    .summ {
        position: fixed;
        top: 120px;
        right: 20px;
        background: var(--blue-main);
        padding: 5px 5px;
        border-radius: 14px;
        border: none;
        color: white;
        opacity: .8;
    }
}
</style>

<style>
.vs__search{
  height: 0;
  margin: 0;
}

.vs__selected{
  text-align: left;
}

</style>