<template>
  <router-link
      to="/services"
      tag="div"
      class="service-tile"
      :style="'background:' +
      (!active ? 'linear-gradient(0deg, rgba(87, 87, 87, 0.45), rgba(87, 87, 87, 0.45)),'
      : 'linear-gradient(141.36deg, rgba(60, 179, 255, 0.64) 2.34%, rgba(60, 179, 255, 0.64) 2.35%, rgba(157, 212, 248, 0.64) 97.2%),')
      + 'url(\'' + img + '\'); background-size: contain;'"
      :class="active ? 'active-tile' : ''"
  >
    <div class="tile-name">
      <div class="tile-name__inner">
        <slot name="default" class="tile-name"></slot>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ServiceTile",
  props: {
    img: String,
    active: Boolean,
    page_link: String
  }
}
</script>

<style scoped lang="scss">
.service-tile{
  border-radius: 20px;
  //height: 20vmax;
  width: 45%;
  position: relative;
  padding-bottom: 45%;
  background: rgba(87, 87, 87, 0.45);
  margin: 2%;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  background-size: contain!important;
  display: flex;
  justify-content: center;
  //padding: 8px;
  transition: all .3s ease-in-out;
  cursor: pointer;
}

.tile-name{
  font-size: 22px;
  font-weight: 500;
  color: white;
  display: flex;
  position: absolute;
  height: 100%;
}

.service-tile:hover{
  transform: scale(1.1);
  background: rgba(60, 179, 255, 0.4);
}

.tile-name__inner{
  margin-top: auto;
  margin-bottom: auto;
}

@media screen and (max-width: 992px){
  .service-tile{
    width: 30%;
    padding-bottom: 30%;
    margin: 1.5%;
  }
}

@media screen and (max-width: 768px) {
  .tile-name{
    font-size: 20px;
  }
}

@media screen and (max-width: 576px){
  .service-tile{
    //height: 45%;
    width: 45%;
    padding-bottom: 45%;
    margin: 1%;
  }

  .tile-name{
    font-size: 18px;
  }
}
</style>